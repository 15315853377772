
export default {
	default: 1,
	header: 2,
	skipLink: 3,
	drawerOverlay: 4,
	drawer: 5,
	modalOverlay: 6,
	modalWindow: 7,
}
