
export default [
	{
		id: 0,
		teamName: 'NHL',
		primary: '#333333',
		secondary: '#666666',
	},
	{
		id: 24,
		teamName: 'Anaheim Ducks',
		primary: '#F47A38',
		secondary: '#B9975B',
	},
	{
		id: 53,
		teamName: 'Arizona Coyotes',
		primary: '#8C2633',
		secondary: '#E2D6B5',
	},
	{
		id: 6,
		teamName: 'Boston Bruins',
		primary: '#FFB81C',
		secondary: '#000000',
	},
	{
		id: 7,
		teamName: 'Buffalo Sabres',
		primary: '#002654',
		secondary: '#FCB514',
	},
	{
		id: 20,
		teamName: 'Calgary Flames',
		primary: '#C8102E',
		secondary: '#F1BE48',
	},
	{
		id: 12,
		teamName: 'Carolina Hurricanes',
		primary: '#CC0000',
		secondary: '#000000',
	},
	{
		id: 16,
		teamName: 'Chicago Blackhawks',
		primary: '#CF0A2C',
		secondary: '#000000',
	},
	{
		id: 21,
		teamName: 'Colorado Avalanche',
		primary: '#6F263D',
		secondary: '#236192',
	},
	{
		id: 29,
		teamName: 'Columbus Blue Jackets',
		primary: '#002654',
		secondary: '#CE1126',
	},
	{
		id: 25,
		teamName: 'Dallas Stars',
		primary: '#006847',
		secondary: '#8F8F8C',
	},
	{
		id: 17,
		teamName: 'Detroit Red Wings',
		primary: '#CE1126',
		secondary: '#DDDDDD',
	},
	{
		id: 22,
		teamName: 'Edmonton Oilers',
		primary: '#041E42',
		secondary: '#FF4C00',
	},
	{
		id: 13,
		teamName: 'Florida Panthers',
		primary: '#041E42',
		secondary: '#C8102E',
	},
	{
		id: 26,
		teamName: 'Los Angeles Kings',
		primary: '#111111',
		secondary: '#A2AAAD',
	},
	{
		id: 30,
		teamName: 'Minnesota Wild',
		primary: '#154734',
		secondary: '#A6192E',
	},
	{
		id: 8,
		teamName: 'Montreal Canadiens',
		primary: '#AF1E2D',
		secondary: '#192168',
	},
	{
		id: 18,
		teamName: 'Nashville Predators',
		primary: '#FFB81C',
		secondary: '#041E42',
	},
	{
		id: 1,
		teamName: 'New Jersey Devils',
		primary: '#CE1126',
		secondary: '#000000',
	},
	{
		id: 2,
		teamName: 'New York Islanders',
		primary: '#00539B',
		secondary: '#F47D30',
	},
	{
		id: 3,
		teamName: 'New York Rangers',
		primary: '#0038A8',
		secondary: '#CE1126',
	},
	{
		id: 9,
		teamName: 'Ottawa Senators',
		primary: '#C52032',
		secondary: '#C2912C',
	},
	{
		id: 4,
		teamName: 'Philadelphia Flyers',
		primary: '#F74902',
		secondary: '#000000',
	},
	{
		id: 5,
		teamName: 'Pittsburgh Penguins',
		primary: '#000000',
		secondary: '#FCB514',
	},
	{
		id: 28,
		teamName: 'San Jose Sharks',
		primary: '#006D75',
		secondary: '#EA7200',
	},
	{
		id: 55,
		teamName: 'Seattle Kraken',
		primary: '#001628',
		secondary: '#99d9d9',
	},
	{
		id: 19,
		teamName: 'St. Louis Blues',
		primary: '#002F87',
		secondary: '#FCB514',
	},
	{
		id: 14,
		teamName: 'Tampa Bay Lightning',
		primary: '#002868',
		secondary: '#BBBBBB',
	},
	{
		id: 10,
		teamName: 'Toronto Maple Leafs',
		primary: '#00205B',
		secondary: '#BBBBBB',
	},
	{
		id: 23,
		teamName: 'Vancouver Canucks',
		primary: '#00205B',
		secondary: '#00843D',
	},
	{
		id: 54,
		teamName: 'Vegas Golden Knights',
		primary: '#B4975A',
		secondary: '#333F42',
	},
	{
		id: 15,
		teamName: 'Washington Capitals',
		primary: '#041E42',
		secondary: '#C8102E',
	},
	{
		id: 52,
		teamName: 'Winnipeg Jets',
		primary: '#041E42',
		secondary: '#004C97',
	},
];
