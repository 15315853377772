
export default (Palette)=> {
	return {
		primary: Palette.grey700,
		secondary: Palette.grey500,
		tertiary: Palette.grey100,
		quaternary: Palette.grey300,
		interactive1: Palette.grey700,
		interactive2: Palette.grey700,
		content: Palette.grey700,
		body: Palette.grey100,
		black: Palette.black,
		white: Palette.white,
	}
};
