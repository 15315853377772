
export const Spacing = {
	horiz: '2rem',
	vert: '2rem',
};

export const ContainerSize = {
	width: {
		mobile: '360px',
		tablet: '750px',
		desktop: '970px',
		wide: '1260px',
	},
};
