
export default {
	white: '#fff',
	grey100: '#efefef',
	grey300: '#ddd',
	grey500: '#666',
	grey700: '#333',
	grey900: '#222',
	black: '#000',
};
